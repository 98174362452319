import qs from "qs";

/**
 * удаление сессионных параметров из урла
 */
if ("replaceState" in window.history) {
  let search = location.search.replace(/^\?*/, "");
  let params = qs.parse(search);

  delete params["autologin"];
  delete params["alog"];
  delete params["sso"];
  delete params["ref"];
  search = qs.stringify(params);
  search = search ? "?" + search : "";

  let url_cur = location.href;
  let url_new;

  if (window.location.origin) {
    url_new =
      window.location.origin + location.pathname + search + location.hash;
  } else {
    url_new =
      location.protocol +
      "//" +
      location.host +
      location.pathname +
      search +
      location.hash;
  }

  if (url_cur !== url_new) {
    try {
      history.replaceState({}, "", url_new);
    } catch (err) {}
  }
}
