import modules from "ym";
import "./jquery";

modules.define("onload", ["jquery"], function (provide, $) {
  const win = $(window);

  provide(function (callback) {
    const onload = () => {
      setTimeout(() => callback());
    };

    if (document.readyState === "complete") {
      onload();
    } else {
      win.on("load", onload);
    }
  });
});
