import $ from "jquery";
import "../components/est-dialog/est-dialog";

export default function (question, options) {
  let params = options || {};
  let dialog = $("<div />").html(
    question.match(/[<>]/) ? question : question.replace(/[\n]/g, "<br />")
  );
  let result = false;

  dialog.estdialog({
    title: params.title || null,
    width: params.width || 400,
    destroyOnClose: true,
    resizable: false,
    draggable: true,
    modal: true,
    close: function () {
      (result ? params.success || $.noop : params.fail || $.noop).apply();
    },
    buttons: [
      {
        text: params.buttonYesText || "Да",
        class: "ui-button ui-button--orange command-close-dialog",
        click: function () {
          result = true;
        },
      },
      {
        text: params.buttonNoText || "Нет",
        class: "ui-button ui-button--grey command-close-dialog",
      },
      {
        text: "",
        class: "",
      },
    ],
  });
}
