import Cookies from "js-cookie";

const domain = window.sf_host ? "." + window.sf_host : location.host;
const path = "/";

const defaultOptions = {
  domain,
  path,
};

export default {
  get(key) {
    return Cookies.get(key);
  },
  set(key, val, options = {}) {
    return Cookies.set(key, val, { ...defaultOptions, ...options });
  },
  remove(key, options = {}) {
    return Cookies.remove(key, { ...defaultOptions, ...options });
  },
};
