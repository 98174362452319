import $ from "jquery";
import modules from "ym";

window["jquery"] = window["jQuery"] = window["$"] = $;
window["modules"] = modules;

const is_function = function (variable) {
  return Object.prototype.toString.call(variable) === "[object Function]";
};

/**
 * Usage: (window.est = window.est || []).push(function () {});
 */
let queuee_old = window.est || [];

window.est = {
  push: function () {
    for (let i = 0, len = arguments.length; i < len; i++) {
      try {
        if (is_function(arguments[i])) {
          arguments[i]();
        }
      } catch (e) {}
    }
  },
};

window.est.push.apply(window.est, queuee_old);
