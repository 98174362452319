// ----------------------------------------------------------------------------
// Новые выпадалки
//

import "../_apps-marks-dropdown.scss";

import $ from "jquery";
import "jquery-ui/ui/position.js";
import scrollto from "../../lib/scrollto";

let timer;
let xhr;

let msieredrawbug = function () {};

// if (document.all && !document.addEventListener) {
//   msieredrawbug = function (jelement) {
//     jelement.length &&
//       jelement.each(function (i, element) {
//         if (element) {
//           element.className = element.className;
//         }
//         if (element && element.parentNode) {
//           element.parentNode.className = element.parentNode.className;
//         }
//       });
//   };
// }

function dropdown_position(toggler) {
  let toggler_border_bottom;
  let toggler_border_right;
  let toggler_border_left;
  let toggler_border_top;
  let toggler_padding_bottom;
  let toggler_padding_right;
  let toggler_padding_left;
  let toggler_padding_top;
  let dropdown_target;
  let arrow_target;
  let dd_container;
  let dd_content;
  let dd_where;
  let dd_distance;
  let dd_position;
  let dd_my;
  let dd_at;
  let dd_flip;
  let dd_content_padding_bottom;
  let dd_content_padding_right;
  let dd_content_padding_left;
  let dd_content_padding_top;
  let dd_padding_bottom;
  let dd_padding_right;
  let dd_padding_left;
  let dd_padding_top;
  let arrow;
  let arrow_my;
  let arrow_at;
  let offset_bottom;
  let offset_right;
  let offset_left;
  let offset_top;
  let is_with_arrow;
  let map = {
    "top-left": ["left bottom", "left top", "flipfit flip"],
    "top-center": ["center bottom", "center top", "fit flip"],
    "bottom-left": ["left top", "left bottom", "flipfit flip"],
    "bottom-right": ["right top", "right bottom", "flipfit flip"],
    "bottom-center": ["center top", "center bottom", "fit flip"],
    "left-top": ["right top", "left top", "flip flip"],
    "left-bottom": ["right bottom", "left bottom", "flip flip"],
    "left-center": ["right center", "left center", "flip fit"],
    "right-top": ["left top", "right top", "flip flip"],
    "right-bottom": ["left bottom", "right bottom", "flip flip"],
    "right-center": ["left center", "right center", "flip fit"],
  };

  dd_container = toggler.next(".est-dropdown");
  dd_container.css({
    position: "absolute",
    left: 0,
    right: "auto",
    top: 0,
    bottom: "auto",
  });

  if (!toggler.is(".est-dropdown-toggle-active") || !dd_container) {
    return false;
  }

  dd_content = dd_container.children(".est-dropdown__content");
  arrow = dd_container.children(".est-dropdown__arrow");

  is_with_arrow = arrow.length > 0;

  dd_distance =
    toggler.attr("data-dd-distance") || dd_container.attr("data-dd-distance");
  dd_distance = parseInt(dd_distance ? dd_distance : "3");
  dd_distance = is_with_arrow ? 8 + dd_distance : dd_distance;

  dd_position =
    toggler.attr("data-dd-position") || dd_container.attr("data-dd-position");
  dd_position = !dd_position ? "bottom-left" : dd_position;

  dd_where = dd_position.replace(/-.*$/, "");

  dropdown_target = toggler.find(".est-dropdown-target");
  dropdown_target = dropdown_target.length ? dropdown_target : toggler;
  arrow_target = toggler.find(".est-dropdown__arrow-target");
  arrow_target =
    dd_position === "top-center" || dd_position === "bottom-center"
      ? dropdown_target
      : arrow_target;

  toggler_border_bottom = parseFloat(toggler.css("borderBottomWidth"));
  toggler_border_right = parseFloat(toggler.css("borderRightWidth"));
  toggler_border_left = parseFloat(toggler.css("borderLeftWidth"));
  toggler_border_top = parseFloat(toggler.css("borderTopWidth"));

  toggler_padding_bottom = parseFloat(toggler.css("paddingBottom"));
  toggler_padding_right = parseFloat(toggler.css("paddingRight"));
  toggler_padding_left = parseFloat(toggler.css("paddingLeft"));
  toggler_padding_top = parseFloat(toggler.css("paddingTop"));

  dd_content_padding_bottom = !dd_content.length
    ? 0
    : parseFloat(dd_content.css("paddingBottom"));
  dd_content_padding_right = !dd_content.length
    ? 0
    : parseFloat(dd_content.css("paddingRight"));
  dd_content_padding_left = !dd_content.length
    ? 0
    : parseFloat(dd_content.css("paddingLeft"));
  dd_content_padding_top = !dd_content.length
    ? 0
    : parseFloat(dd_content.css("paddingTop"));

  dd_padding_bottom =
    parseFloat(dd_container.css("paddingBottom")) + dd_content_padding_bottom;
  dd_padding_right =
    parseFloat(dd_container.css("paddingRight")) + dd_content_padding_right;
  dd_padding_left =
    parseFloat(dd_container.css("paddingLeft")) + dd_content_padding_left;
  dd_padding_top =
    parseFloat(dd_container.css("paddingTop")) + dd_content_padding_top;

  offset_bottom = dd_padding_bottom - toggler_padding_bottom;
  offset_right = dd_padding_right - toggler_padding_right;
  offset_left = dd_padding_left - toggler_padding_left;
  offset_top = dd_padding_top - toggler_padding_top;

  if (map[dd_position]) {
    dd_my = map[dd_position][0];
    dd_at = map[dd_position][1];
    dd_flip = toggler.attr("data-dd-flip") || map[dd_position][2];
  }

  // если нужно, увеличиываем ширину выпадалки
  if (
    dd_my &&
    dd_at &&
    $.inArray(dd_position, [
      "top-left",
      "bottom-left",
      "top-right",
      "bottom-right",
    ]) !== -1
  ) {
    if (arrow_target.length || dd_container.is(".est-dropdown--for-menu")) {
      if (
        dd_container.width() <
        dropdown_target.width() + offset_left + offset_right
      ) {
        dd_container.css({
          width: dropdown_target.width() + offset_left + offset_right,
        });
      }
    } else {
      // arrow_target = dd_container.width() - offset_left - offset_right < dropdown_target.width() ? dd_container : dropdown_target;
      arrow_target =
        dd_container.width() < dropdown_target.width()
          ? dd_container
          : dropdown_target;
    }
  }

  if (dd_my && dd_at) {
    // сбрасываем положение стрелки
    arrow.css({
      position: "absolute",
      bottom: "auto",
      right: "auto",
      left: 0,
      top: 0,
    });

    // сбрасываем положение выпадалки
    dd_container.css({
      position: "absolute",
      bottom: "auto",
      right: "auto",
      left: 0,
      top: 0,
    });

    // позиционируем выпадалку
    dd_container.position({
      collision: dd_flip,
      of: dropdown_target,
      my: dd_my,
      at: dd_at,
      using: function (pos, feedback) {
        let arrow_dir =
          dd_where === "top" || dd_where === "bottom"
            ? feedback.vertical
            : feedback.horizontal;

        // определяем направление стрелки
        dd_container.attr("data-dd-arrow", arrow_dir);

        // уточняем позицию вертикальной выпадалки
        if (dd_where === "top" || dd_where === "bottom") {
          if (dd_position.match(/^(bottom|top)-(left|right)$/)) {
            if (feedback.element.left < feedback.target.left) {
              pos.left += offset_right;
            } else {
              pos.left -= offset_left;
            }
          }
          if (feedback.vertical == "top") {
            pos.top += dd_distance;
            arrow_my = "center top";
            arrow_at = "center bottom";
          }
          if (feedback.vertical == "bottom") {
            pos.top -= dd_distance;
            arrow_my = "center bottom";
            arrow_at = "center top";
          }
        }

        // уточняем позицию горизонтальной выпадалки
        if (dd_where == "left" || dd_where == "right") {
          if (feedback.horizontal == "left") {
            pos.left += dd_distance;
            arrow_my = "left center";
            arrow_at = "right center";
          }
          if (feedback.horizontal == "right") {
            pos.left -= dd_distance;
            arrow_my = "right center";
            arrow_at = "left center";
          }
          if (feedback.vertical == "top") {
            pos.top -= offset_top;
          }
          if (feedback.vertical == "bottom") {
            pos.top += offset_bottom;
          }
        }

        // позиционируем выпадалку
        dd_container.css(pos);

        // позиционируем стрелку
        arrow.position({
          of: arrow_target.length ? arrow_target : dropdown_target,
          collision: "none",
          my: arrow_my,
          at: arrow_at,
        });

        // уточняем позицию стрелки
        if (dd_where == "top" || dd_where == "bottom") {
          if (feedback.vertical == "top") {
            arrow.css({
              bottom: "100%",
              top: "auto",
            });
          }
          if (feedback.vertical == "bottom") {
            arrow.css({
              bottom: "auto",
              top: "100%",
            });
          }
        } else {
          if (feedback.horizontal == "left") {
            arrow.css({
              left: "auto",
              right: "100%",
            });
          }
          if (feedback.horizontal == "right") {
            arrow.css({
              left: "100%",
              right: "auto",
            });
          }
        }
      },
    });
  }

  msieredrawbug(toggler);
}

function activate_toggler(toggler) {
  clearTimeout(timer);

  if (toggler.is(".est-dropdown, .est-select__dropdown")) {
    toggler = toggler.prev(".est-dropdown-toggle");
  }

  if (toggler.hasClass("est-dropdown-toggle-active")) {
    return false;
  }

  let event_activate = $.Event("dropdown-activate");
  let event_activated = $.Event("dropdown-activated");

  toggler.trigger(event_activate);

  if (event_activate.isDefaultPrevented()) {
    return false;
  }

  toggler.addClass("est-dropdown-toggle-active");

  let dd_url;
  let blabla = function () {
    if (toggler.is(".est-dropdown-toggle-active")) {
      dropdown_position(toggler);
      toggler.trigger(event_activated);

      let dd_container = toggler.next(".est-dropdown");
      let dd_content = dd_container.children(".est-dropdown__content");

      dd_content
        .find(
          ":input:enabled:visible:not([data-pick-date]):not([data-pick-time]):first"
        )
        .trigger("focus");
    }
  };

  dd_url = toggler.attr("data-dd-url");

  if (dd_url) {
    toggler.next(".est-dropdown").remove();

    xhr && xhr.abort();
    xhr = $.ajax({
      url: dd_url,
      dataType: "jsonp",
      data: {
        sf_format: "jsonp",
      },
    });

    xhr.done(function (data, textStatus, jqXHR) {
      toggler.after(data);
      blabla();
    });

    xhr.fail(function (jqXHR, textStatus, errorThrown) {});
  } else {
    blabla();
  }
}

function deactivate_toggler(toggler) {
  clearTimeout(timer);
  toggler = toggler.is(".est-dropdown, .est-select__dropdown")
    ? toggler.prev(".est-dropdown-toggle")
    : toggler;
  toggler.filter(".est-dropdown-toggle-active").length &&
    $.datepicker &&
    $.datepicker.dpDiv &&
    $.datepicker.dpDiv.length &&
    $.datepicker.dpDiv.hide();
  toggler.removeClass("est-dropdown-toggle-active");
  msieredrawbug(toggler);
  toggler.trigger("dropdown-deactivated");
}

function deactivate_toggler_ontimeout(toggler, timeout) {
  clearTimeout(timer);
  toggler = toggler.is(".est-dropdown, .est-select__dropdown")
    ? toggler.prev(".est-dropdown-toggle")
    : toggler;
  timer = setTimeout(function () {
    deactivate_toggler(toggler);
  }, timeout || 1000);
}

function deactivate_togglers(togglers) {
  let tmp = $()
    .add(
      togglers
        .filter(".est-dropdown, .est-select__dropdown")
        .prev(".est-dropdown-toggle-active")
    )
    .add(togglers.filter(".est-dropdown-toggle-active"));
  tmp.filter(".est-dropdown-toggle-active").length &&
    $.datepicker &&
    $.datepicker.dpDiv &&
    $.datepicker.dpDiv.length &&
    $.datepicker.dpDiv.hide();
  tmp.removeClass("est-dropdown-toggle-active");
  clearTimeout(timer);
  msieredrawbug(tmp);
  tmp.trigger("dropdown-deactivated");
}

function deactivate_all_togglers() {
  clearTimeout(timer);
  deactivate_togglers(
    $('.est-dropdown-toggle-active:not([data-dd-permanent="1"])')
  );
}

function activate_toggler_and_deactivate_others(toggler) {
  toggler = toggler.is(".est-dropdown, .est-select__dropdown")
    ? toggler.prev(".est-dropdown-toggle")
    : toggler;
  let dropdown = toggler.next(".est-dropdown, .est-select__dropdown");
  let tmp = $('.est-dropdown-toggle-active:not([data-dd-permanent="1"])')
    .not(toggler)
    .not(toggler.parents(".est-dropdown").prev(".est-dropdown-toggle-active"));
  activate_toggler(toggler);
  deactivate_togglers(tmp);
}

$(document).on("mouseenter", '[data-dd-event="hover"]', function () {
  activate_toggler_and_deactivate_others($(this));
});

$(document).on("mouseleave", '[data-dd-event="hover"]', function () {
  deactivate_toggler_ontimeout($(this), 1000);
});

$(document).on(
  "mouseenter",
  '[data-dd-event="hover"] + .est-dropdown',
  function () {
    activate_toggler_and_deactivate_others(
      $(this).prev(".est-dropdown-toggle")
    );
  }
);

$(document).on(
  "mouseleave",
  '[data-dd-event="hover"] + .est-dropdown',
  function () {
    deactivate_toggler_ontimeout($(this).prev(".est-dropdown-toggle"), 1000);
  }
);

$(document).on(
  "change",
  '.est-select__dropdown input[type="radio"]',
  function () {
    let dropdown;
    let toggler;
    let label;
    let input;

    input = $(this);
    dropdown = input.closest(".est-select__dropdown");
    toggler = dropdown.prev(".est-dropdown-toggle");
    label = input.next("label");
    label = label.length ? label : input.closest("label");

    toggler.text($.trim(label.text()) || String.fromCharCode(160));
  }
);

$(document).on(
  "click",
  '.est-dropdown-toggle:not([data-dd-event="hover"])',
  function (event) {
    let toggler = $(this);

    if (toggler.is(".est-dropdown-toggle-active")) {
      if (!toggler.is("input")) {
        deactivate_toggler(toggler);
      }
    } else {
      activate_toggler_and_deactivate_others(toggler);
    }

    event.preventDefault();
    event.stopPropagation();
  }
);

$(document).on(
  "focus",
  'input.est-dropdown-toggle:not([data-dd-event="hover"])',
  function (event) {
    activate_toggler_and_deactivate_others($(this));
  }
);

$(document).on("blur", "input.est-dropdown-toggle-active", function (event) {
  deactivate_toggler_ontimeout($(this), 100);
});

$(document).on("click", ".est-dropdown", function () {
  dropdown_position($(this).prev(".est-dropdown-toggle"));
});

$(document).on(
  "click",
  ".est-dropdown .btn-close, .est-dropdown .btn-close-small, .command-close-dropdown",
  function (event) {
    deactivate_toggler(
      $(this).closest(".est-dropdown").prev(".est-dropdown-toggle")
    );

    event.preventDefault();
    event.stopPropagation();
  }
);

$(document).on("render", ".est-select", function () {
  let checked = $();
  let select = $("ul", this);
  let button = $(".ui-button-select", this);

  checked = checked.length
    ? checked
    : select.find("input:enabled:checked").prop("checked", true);
  checked = checked.length
    ? checked
    : select.find("input:enabled:first").prop("checked", true);
  checked = checked.length ? checked : select.find("input:first");

  button.text(checked.next("span").text());
});

$(document).on("dropdown-activated", ".est-select", function () {
  let checked = $("input:checked", this);
  let list = $(".est-select__dropdown", this);
  let item = checked.length ? checked.closest("label") : null;

  if (item && item.length) {
    scrollto(list, item, {
      axis: "y",
      offset: {
        top: (-1 / 2) * (list.height() - item.height()),
      },
    });
  } else {
    scrollto(list, 0, {
      axis: "y",
    });
  }
});

$(document).on("est-dropdown-auto", function () {
  $('.est-dropdown-toggle[data-dd-auto="1"]').each(function () {
    activate_toggler($(this).removeAttr("data-dd-auto"));
  });
});

$(document).on("click", "body", function (event) {
  let target = $(event.target);
  let datepicker = target.closest(".ui-datepicker");
  let parent = target.closest(".est-dropdown-toggle, .est-dropdown");
  let dropdown;
  let children;

  if (datepicker.length) {
  } else if (parent.length) {
    dropdown = parent.is(".est-dropdown")
      ? parent
      : parent.prev(".est-dropdown-toggle");
    children = dropdown.find(".est-dropdown-toggle-active");
    children.length && deactivate_togglers(children);
  } else {
    deactivate_all_togglers();
  }
});

$(window).on("resize", function () {
  [...document.querySelectorAll(".est-dropdown-toggle-active")].forEach(
    function (toggler) {
      dropdown_position($(toggler));
    }
  );
});

$(function () {
  $(document).trigger("est-dropdown-auto");
});
