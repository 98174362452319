// ----------------------------------------------------------------------------
// засветление при переходе на другую страницу

import "./est-unload.scss";

import $ from "jquery";

let timeout = null;
let ignore_onbeforeunload = false;

const docElement = $(document.documentElement);

const callback = () => {
  clearTimeout(timeout);

  timeout = setTimeout(() => {
    docElement.removeClass("est-unload");
  }, 30 * 1000);

  if (!ignore_onbeforeunload) {
    docElement.addClass("est-unload");
  }
};

$(window).on("beforeunload unload", callback);

// запрещаем засветление, если нажимается ссылка на телефон, почту или скайп
$('a[href^="tel:"], a[href^="mailto:"], a[href^="skype:"]').on("click", () => {
  ignore_onbeforeunload = true;
});
