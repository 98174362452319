"use strict";

import $ from "jquery";
import modules from "ym";
import "jquery.scrollto";

modules.define("jquery-scrollto", function (provide) {
  if (typeof $.fn.scrollTo !== "undefined") {
    provide($.fn.scrollTo);
  }
});
