// import Cookies from 'js-cookie';
// import storage from '../lib/storage';
const storage = null;

import { language } from "../lib/language";

const isAuthenticated = !!document.querySelector(".body--auth-1");

function addRememberParam(url, val) {
  const key = "remember-language";

  //if (isAuthenticated) {
  return `${url}${url.indexOf("?") !== -1 ? "&" : "?"}${key}=${val}`;
  //} else {
  //  return url;
  //}
}

function checkPrefferedLanguage(prefferedLanguage) {
  if (prefferedLanguage && prefferedLanguage !== language) {
    let langPrefix = prefferedLanguage === "ua" ? "/" : "/ru/";
    let redirectTo = location.pathname.replace(/^(\/ru|\/ua|)\//, langPrefix);
    redirectTo = redirectTo + location.search;
    redirectTo = addRememberParam(redirectTo, prefferedLanguage);
    redirectTo = redirectTo + location.hash;
    location.assign(redirectTo);
  }
}

// if (storage) {
//     if (isAuthenticated) {
//         storage.local.setItem('remember-language', language);
//     } else {
//         storage.local.getItem('remember-language', prefferedLanguage => {
//             checkPrefferedLanguage(prefferedLanguage);
//         });
//     }
// }

// if (!isAuthenticated) {
//     checkPrefferedLanguage(Cookies.get('remember-language'));
// }

[...document.querySelectorAll("[data-remember-language]")].forEach((link) => {
  link.addEventListener("click", (event) => {
    event.preventDefault();
    const href = link.getAttribute("href");
    const language = link.getAttribute("data-remember-language");
    const redirectTo = addRememberParam(href, language);

    storage || location.assign(redirectTo);
    storage &&
      storage.local.setItem("remember-language", language, () => {
        location.assign(redirectTo);
      });
  });
});
