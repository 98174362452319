// ----------------------------------------------------------------------------
// Подтверждение перехода по ссылке и аяксовая подгрузка контента

import $ from "jquery";
import "jquery-form"; // for $.fn.ajaxSubmit
import "./est-dialog/est-dialog";
import confirm from "../lib/confirm";

function handler(event, confirmed) {
  let element = this;
  let source = $(element);
  let target = source.attr("data-target");
  let question = source.attr("data-confirm");
  // let result = !question || confirm(question);
  let result = !question || !!confirmed;
  let url =
    source.attr("data-href") ||
    source.attr("data-url") ||
    source.attr("href") ||
    source.attr("action") ||
    "";
  let url_space_pos = url.indexOf(" ");
  let url_selector = null;

  if (question && !confirmed) {
    confirm(question, {
      title: source.attr("data-confirm-title"),
      width: source.attr("data-confirm-width"),
      success: () => {
        handler.call(this, event, true);
      },
    });
  }

  if (result && target) {
    event.preventDefault();

    url_selector =
      url_space_pos >= 0 ? url.slice(url_space_pos, url.length) : null;
    url = url_space_pos >= 0 ? url.slice(0, url_space_pos) : url;

    let ajax_data = {
      url: url,
      overlay: ":dialog" === target,
      data: {
        sf_format: "jsonp",
      },
      dataType: "jsonp",
      complete: function () {
        $.data(document, "ajax-send", false);
      },
      success: function (data) {
        if (data && data.redirect) {
          location.href = data.redirect;

          return true;
        }

        let html = !url_selector
          ? data
          : $("<div />").append($.parseHTML(data)).find(url_selector);

        if (source.attr("data-target-once")) {
          source.removeAttr("data-target-once");
          source.removeAttr("data-target");
          source.removeAttr("data-href");
          source.removeAttr("data-url");
        }

        if (target === ":dialog") {
          $(document).trigger("command-close-dialogs");

          let params;
          let dialog;
          let tmp = $("<div />").append(html);

          if (tmp.children().length === 1) {
            dialog = $(tmp.children()[0]);
            setTimeout(function () {
              tmp.remove();
            }, 5000);
          } else {
            dialog = tmp;
          }

          params = {
            title:
              dialog.attr("data-dialog-title") ||
              dialog.attr("title") ||
              source.attr("data-dialog-title") ||
              null,
            width:
              dialog.attr("data-dialog-width") ||
              source.attr("data-dialog-width") ||
              300,
            height:
              dialog.attr("data-dialog-height") ||
              source.attr("data-dialog-height") ||
              null,
            destroyOnClose: true,
            resizable: false,
            draggable: true,
            autoOpen: true,
            modal: true,
          };

          if (!params.title) {
            delete params.title;
          }
          if (!params.width) {
            delete params.width;
          }
          if (!params.height) {
            delete params.height;
          }

          dialog.appendTo(document.body);
          dialog.estdialog(params);
        } else if (target === ":this") {
          $(this).empty().html(html);
        } else {
          $(target).empty().html(html);
        }
      },
    };

    if (!$.data(document, "ajax-send")) {
      $.data(document, "ajax-send", true);
      event.type === "submit" && source.ajaxSubmit(ajax_data);
      event.type === "submit" || $.ajax(ajax_data);
    }
  } else if (result) {
    location.href = url;
  }

  return result;
}

$(document).on("submit", "form[data-confirm], form[data-target]", handler);
// :not([data-toggle]) - добавлено, т.к. конфликтует с bootstrap
$(document).on(
  "click",
  "[data-confirm]:not([data-busy]):not(form), [data-target]:not([data-toggle]):not([data-busy]):not(form)",
  handler
);
