// Трекинг кликов
// https://estua.megaplan.ru/task/1006463/card/
// https://estua.megaplan.ru/task/1006927/card/

import $ from "jquery";
import liburl from "url";
import hash from "../lib/hash";
import modules from "ym";
import "../modules/cors-domains";
import "core-js/features/array/includes"; // for IE 11

import Analytics from "analytics";
import googleTagManager from "@analytics/google-tag-manager";
import googleAnalytics from "@analytics/google-analytics";

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

const sf_module = global.sf_module || null;
const sf_action = global.sf_action || null;
const $document = $(document);
const debug = false;

const gtm_plugins = [googleTagManager({ containerId: "GTM-KGG3BX" })];

// Добавить отдельный гугл тэг менеджер на profisite.est.ua
// https://estua.megaplan.ru/task/1012363/card/
if (`${sf_module}/${sf_action}` === "profiSiteLanding/index") {
  gtm_plugins.push({
    ...googleTagManager({ containerId: "GTM-K8L4TL8" }),
    name: "google-tag-manager-welldone",
  });
}

const gtm = Analytics({
  app: "est.ua",
  plugins: gtm_plugins,
});

const dev = Analytics({
  app: "est.ua",
  plugins: [googleAnalytics({ trackingId: "UA-4984191-30" })],
});

gtm.on("ready", () => {
  hash.removeItem("_ga");
});

if (debug) {
  dev.on("page", ({ payload }) => {
    console.log("dev page:", payload);
  });

  dev.on("track", ({ payload }) => {
    console.log("dev track:", payload);
  });

  gtm.on("page", ({ payload }) => {
    console.log("gtm page:", payload);
    console.log("gtm data", window.dataLayer || []);
  });

  gtm.on("track", ({ payload }) => {
    console.log("gtm event:", payload);
  });

  // gtm.getState()
  // gtm.user()
}

$document.on("click", "[data-track-click]", function () {
  let i;
  let attr;
  let href;
  for (i = 0; i < 10; i++) {
    attr = i ? "data-track-click-" + i : "data-track-click";
    href = this.getAttribute(attr);
    href && gtm.page({ url: href, path: href });

    if (false) {
      (window.dataLayer = window.dataLayer || []).push({
        event: "VirtualPageview",
        virtualPageURL: href,
      });
    }
  }
});

// отсылаем в аналитикс переходы по объявлениям аггрегатора
$document.on("click", "a[href]", function () {
  const href = this.getAttribute("href");
  const url = liburl.parse(href, true);

  const rePath = /^(?:\/ru|\/ua|)\/(\d+)\/go\/.*/;
  const resPath = rePath.exec(url.path);
  const record_id = resPath !== null && resPath[1] ? resPath[1] : null;

  if (record_id) {
    modules.require(["cors-domains"], (domains) => {
      const reDomains = domains.map((domain) => escapeRegExp(domain));
      const reHost = new RegExp("^(?:(.+).)?(" + reDomains.join("|") + ")$");

      const resHost = reHost.exec(url.host);
      const username = resHost !== null && resHost[1] ? resHost[1] : null;
      const domain = resHost !== null && resHost[2] ? resHost[2] : null;

      if (username && domain && record_id) {
        // счетчик редиректов в пакете Аггрегатор
        dev.page({
          url: `${url.protocol}//${domain}/aggregator/user-${username}/apps`,
          path: `/aggregator/user-${username}/apps`,
        });

        const hitUrl = [
          `https://${url.host}/${record_id}/hit/`,
          `?z=${Math.random()}`,
        ].join("");

        const hitOpts = {
          credentials: "include",
          method: "POST",
        };

        // счетчик просмотров объявлений
        fetch(hitUrl, hitOpts).catch(() => {});
      }
    });
  }
});
