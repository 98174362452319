import modules from "ym";
import "./loader_type_all";

modules.define("cors-domains", ["loader_type_all"], function (provide, loader) {
  loader({
    js: "/custom_domains.php",
    success: function () {
      provide(window.customDomains || ["est.ua"]);
    },
  });
});
