import getParamsFromUrl from "./get-params-from-url";
import cookies from "./est-cookies";

const query = getParamsFromUrl(location.href);

const utm_data = Object.keys(query)
  .filter((key) => (key || "").match(/^utm_/))
  .reduce((acc, key) => ({ ...acc, [key]: query[key] }), {});

if (Object.keys(utm_data).length) {
  const utm_data_full = {
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_content: "",
    utm_term: "",
    ...utm_data,
  };

  Object.entries(utm_data_full).forEach(([key, val]) => {
    cookies.set("est-" + key.replace(/_+/g, "-"), val || "", { expires: 7 });
  });
}
