import qs from "qs";

const parse = function () {
  return qs.parse(location.hash.replace(/^#/, ""));
};

const build = function (data) {
  return qs.stringify(data).replace(/=(&|$)/, "$1");
};

const replace = function (hash) {
  if (history.replaceState) {
    try {
      history.replaceState(
        {},
        "",
        location.href.replace(/(#.*|)$/, hash ? "#" + hash : "")
      );
    } catch (err) {}
  } else if ("" + location.hash !== hash) {
    location.hash = hash;
  }
};

export default {
  hasItem: function (key) {
    let data = parse();

    return key in data;
  },
  getItem: function (key) {
    let data = parse();

    return key in data ? data[key] : null;
  },
  setItem: function (key, val) {
    let data = parse();

    data[key] = val;

    replace(build(data));
  },
  removeItem: function (key) {
    let data = parse();

    delete data[key];

    replace(build(data));
  },
  parse: function () {
    return parse();
  },
  replace: function (hash) {
    replace(hash);
  },
};
